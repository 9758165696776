
@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO: should remove once `styled-components` is dropped */
/* MEMO: suppress unwanted base styles from `styled-components` */
@layer base {
  ul {
    list-style: none !important;
  }
}